import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SELF_SERVE_BASE_URL}/api/mfa`,
  withCredentials: true,
});

export const getAvailableFactorsAPI = () => axiosInstance.get('/factors/available');

export const getRegisteredFactorsAPI = () => axiosInstance.get('/factors/registered');

export const startEmailOtpAPI = () => axiosInstance.post(
  '/start/email', {},
);

export const verifyEmailOtpAPI = ({ otp }) => axiosInstance.post(
  '/verify/email', { otp },
);
