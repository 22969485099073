import axios from 'axios';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1/user`,
  withCredentials: true,
});

const loginAPI = (email, password) => axiosRequest.post('/login', {
  email,
  password,
});

export default loginAPI;
