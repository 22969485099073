const constants = {
  maxRecordsInTable: 10,
  maxRecordInAMLTable: 5,
  analyticsRefreshTime: 10 * 60 * 1000, // 10 mins expiry time for url
  resendOTPTimeInSeconds: 60,
};

const allowedPermissions = {
  AUDIT: 'audit',
  REVIEW: 'reviewPortal',
};

const DOCUMENTATION_PORTAL_URL = process.env.REACT_APP_DOCUMENTATION_PORTAL_URL || 'https://documentation.hyperverge.co';

const DEPRECATED_DOCUMENTATION_PORTAL_URL = 'https://developer.hyperverge.co';

const DEFAULT_BILLING_TYPE = 'tax';

module.exports = {
  constants,
  allowedPermissions,
  DOCUMENTATION_PORTAL_URL,
  DEPRECATED_DOCUMENTATION_PORTAL_URL,
  DEFAULT_BILLING_TYPE,
};
