import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../Details.scss';
import '../../Details.css';
import {
  Timeline,
} from '@mui/lab';
import CreatedAtAndStatus from '../../CreatedAtAndStatus';
import VideoPlayer from './VideoPlayer';
import TimelineCardV2 from './TimelineCardV2';

const VideoCardV2Panel = ({
  item, activeAttemptIndex, attemptIndex, moduleId,
}) => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('No Video Found!');
  const { statements, videoRef: videoSrc } = item?.extractedData?.details || {};
  const videoPlayerRef = useRef();

  return (
    <div role="tabpanel" id="details__tab__video__container" hidden={activeAttemptIndex !== attemptIndex}>
      <CreatedAtAndStatus createdAt={item?.createdAt} status={item?.status} />
      {!isError && videoSrc ? (
        <div id="video_details_row">
          <div id="video_details_col">
            <VideoPlayer
              videoSrc={videoSrc}
              setIsError={setIsError}
              setErrorMessage={setErrorMessage}
              videoPlayerRef={videoPlayerRef}
              statements={statements}
              activeAttemptIndex={activeAttemptIndex}
              attemptIndex={attemptIndex}
              moduleId={moduleId}
            />
          </div>
          <div id="video_details_col">
            <p id="video_details_col_title">VERIFICATION RESULT</p>
            <div id="video_details_col_timeline">
              <Timeline>
                {
                  statements
                  && statements.map(
                    (stmt, statementIndex) => (
                      <TimelineCardV2
                        key={stmt.statementId}
                        statement={stmt}
                        statementIndex={statementIndex}
                        attemptIndex={attemptIndex}
                        moduleId={moduleId}
                        videoPlayerRef={videoPlayerRef}
                      />
                    ),
                  )
                }
              </Timeline>
            </div>
          </div>
        </div>
      ) : <p id="details__tab__video__error">{errorMessage}</p>}
    </div>
  );
};

VideoCardV2Panel.propTypes = {
  item: PropTypes.object.isRequired,
  activeAttemptIndex: PropTypes.number.isRequired,
  attemptIndex: PropTypes.number.isRequired,
  moduleId: PropTypes.string.isRequired,
};

export default VideoCardV2Panel;
