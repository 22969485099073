import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fail from '../../../assests/icons/fail.svg';
import ordinalSuffix from '../../../utils/helpers';
import Pass from '../../../assests/icons/pass.svg';

function CustomTabs({
  id, className, tabIndex, setTabIndex, tabArray, tabIdPrefix, ariaControlsPrefix,
}) {
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Tabs
      id={id}
      className={`${className} custom_tabs`}
      value={tabIndex}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons={false}
      aria-label="basic tabs example"
    >
      {tabArray.map((item, index) => (
        <Tab
          key={item.id}
          label={`${ordinalSuffix(tabArray.length - index)} attempt ${index === 0 ? '(latest)' : ''}`}
          id={`${tabIdPrefix}-${index}`}
          icon={item.isSuccess ? <img src={Pass} alt="tick" className="details__status_icon" /> : <img src={Fail} alt="cross" className="details__status_icon" />}
          iconPosition="start"
          aria-controls={`${ariaControlsPrefix}-${index}`}
          className={`${tabIndex === index ? 'tabActive' : ''}`}
        />
      ))}
    </Tabs>
  );
}

CustomTabs.defaultProps = {
  id: null,
  className: null,
  tabIdPrefix: 'simple-tab',
  ariaControlsPrefix: 'simple-tabpanel',
};

CustomTabs.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
  tabArray: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  tabIdPrefix: PropTypes.string,
  ariaControlsPrefix: PropTypes.string,
};

export default CustomTabs;
