const rudderstackEvents = {
  DASHBOARD_SIGNUP_LAUNCH: 'dashboard_signUp_launch',
  DASHBOARD_SIGNUP_CTACLICK: 'dashboard_signUp_ctaClick',
  DASHBOARD_HOMEPAGE_LAUNCH: 'dashboard_homepage_launch',
  DASHBOARD_LOGIN_LAUNCH: 'dashboard_login_launch',
  DOCUMENTATION_LOGIN_LAUNCH: 'documentation_login_launch',
  DASHBOARD_LOGIN_CLICK: 'dashboard_login_ctaClick',
  DASHBOARD_NAVBAR_HOME_CLICK: 'dashboard_navbar_homeClick',
  DASHBOARD_NAVBAR_APPLICATIONS_CLICK: 'dashboard_navbar_applicationsClick',
  DASHBOARD_NAVBAR_WPRKFLOWS_CLICK: 'dashboard_navbar_workflowsClick',
  DASHBOARD_NAVBAR_CREDENTIALS_CLICK: 'dashboard_navbar_credentialsClick',
  DASHBOARD_NAVBAR_DOCUMENTATION_CLICK: 'dashboard_navbar_documentationClick',
  DASHBOARD_NAVBAR_DEVHUB_CLICK: 'dashboard_navbar_devHubClick',
  DASHBOARD_NAVBAR_LOGOUT_CLICK: 'dashboard_navbar_logoutClick',
  DASHBOARD_NAVBAR_USER_CLICK: 'dashboard_navbar_userClick',
  DASHBOARD_NAVBAR_PRICING_CLICK: 'dashboard_navbar_princingInfoClick',
  DASHBOARD_NAVBAR_USAGE_CLICK: 'dashboard_navbar_usage_click',
  DASHBOARD_HOME_GETLINK_CLICK: 'dashboard_home_getLinkClick',
  DASHBOARD_HOME_DOCUMENTATION_CLICK: 'dashboard_home_documentationClick',
  DASHBOARD_HOME_WEBDEMO_LINK_OPEN: 'dashboard_home_webDemoLinkOpen',
  DASHBOARD_HOME_MOBILEDEMO_LINK_OPEN: 'dashboard_home_mobileDemoLinkOpen',
  DASHBOARD_HOME_WEBDEMO_SEND_EMAIL_CLICK: 'dashboard_home_webDemoSendEmailClick',
  DASHBOARD_HOME_MOBILEDEMO_SENDEMAIL_CLICK: 'dashboard_home_mobileDemoSendEmailClick',
  DASHBOARD_APPLICATIONSTABLE_NEWAPPBUTTON_CLICK: 'dashboard_applicationsTable_newApplicationButtonClick',
  DASHBOARD_APP_NEW_APP_EMPTY_STATE_CLICK: 'dashboard_applicationsTable_newApplicationEmptyStateClick',
  DASHBOARD_APP_OVERLAY_GENLINK_CLICK: 'dashboard_applicationsTable_overlayGenerateLinkClick',
  DASHBOARD_APP_NEW_APP_LINK_OPEN: 'dashboard_applicationsTable_newApplicationLinkOpen',
  WEB_DEMO_LANDING_GETSTARTED_CLICK: 'webDemo_landing_getStartedClick',
  WEB_DEMO_RESULTS_LAUNCH: 'webDemo_results_launch',
  DASHBOARD_APPLICATIONS_SEARCH: 'dashboard_applicationsTable_applicationSearch',
  DASHBAORD_APPLICATIONS_FILTER_CHANGE: 'dashboard_applicationsTable_applicationFilterChange',
  DASHBOARD_APPLICATIONS_CSV_DOWNLOAD: 'dashboard_applicationsTable_applicationCSVDownload',
  DASHBOARD_APPLICATIONS_APPLICATION_CLICK: 'dashboard_applicationsTable_applicationClick',
  DASHBOARD_RECORD_LAUNCH: 'dashboard_applicationOverview_launch',
  DASHBOARD_RECORD_APPROVED_CLICK: 'dashboard_applicationOverview_applicationApproved',
  DASHBOARD_RECORD_DECLINED_CLICK: 'dashboard_applicationOverview_applicationDeclined',
  DASHBOARD_RECORD_COMMENT_ADDED: 'dashboard_applicationOverview_applicationCommentAdded',
  DASHBOARD_RECORD_NAVIGATION_CLICK: 'dashboard_applicationOverview_applicationNavigationClick',
  DASHBOARD_GOOGLE_LOGIN_CLICK: 'dashboard_google_sso_click',
  DASHBOARD_FORGOT_PASSWORD_CLICK: 'dashboard_forgot_password_click',
  DASHBOARD_VKYC_LINK_CLICK: 'dashboard_home_vkyc_link_click',
  DASHBOARD_WORKFLOWS_LAUNCH: 'dashboard_workflows_launch',
  DASHBOARD_CREDENTIAL_COPY: 'dashboard_credentials_credential_copy',
  DASHBOARD_CREDENTIAL_VIEW: 'dashboard_credentials_credential_view',
  DASHBOARD_USAGE_MONTH_CHANGE: 'dashboard_usage_month_change',
  DASHBOARD_USAGE_FETCH_MODULE_LOGS_CLICK: 'dashboard_usage_download_moduleLogs_click',
  DASHBOARD_USAGE_FETCH_PLATFORM_LOGS_CLICK: 'dashboard_usage_download_platformLogs_click',
};

export default rudderstackEvents;
