import { get } from 'lodash';
import { getImageTypesBasedOnConfig } from '../../Summary/utils';

const setImageTypeBasedOnConfig = (data) => data.map((item) => {
//   Example
//   item.endpointModule.imageTypes = {
//     image: {
//       condition: 'request.maskAadhaar === "no"',
//     },
//     cropped: {
//       condition: 'request.maskAadhaar === "yes" && request.maskAadhaar === "yes"',
//     },
//   };
  const imageTypes = get(item, 'endpointModule.imageTypes', null);
  const requestData = get(item, 'extractedReqData[0][0]', {});
  const finalImageType = getImageTypesBasedOnConfig(imageTypes, requestData, false);
  return { ...item, imageTypeToShow: finalImageType };
});
export default setImageTypeBasedOnConfig;
