import React from 'react';
import PropTypes from 'prop-types';
import CommonTabHeader from '../CommonTabHeader';
import Media from './Media';
import Table from './Table';

function Panel({
  index, moduleId, selectedAttemptIndex, item, createdAt, status,
}) {
  return (
    <>
      <div
        role="tabpanel"
        hidden={selectedAttemptIndex !== index}
        id={`generic_common_card_${moduleId}_tabpanel-${index}`}
        aria-labelledby={`generic_common_card_${moduleId}_tab-${index}`}
      >
        <div className="generic_common_card__tab__container">
          <CommonTabHeader status={status} createdAt={createdAt} />
          <div className="generic_common_card__tab__body">
            <Media item={item} />
            <Table item={item} />
          </div>
        </div>
      </div>

    </>
  );
}

Panel.defaultProps = {
  status: '',
  createdAt: '',
};

Panel.propTypes = {
  moduleId: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  selectedAttemptIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  status: PropTypes.string,
  createdAt: PropTypes.string,
};

export default Panel;
