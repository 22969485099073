import { get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import loginAPI from '../../../../api/auth';
import { verifyEmailOtpAPI } from '../../../../api/mfa';
import { updateIsAuthenticated } from '../../../../reducers/user';
import { useShowCustomAlert } from '../../../../utils/lib';
import logo from '../../../../assests/images/logoPlain.png';
import alert from '../../../../assests/icons/alert-v2.svg';
import MFArocket from '../../../../assests/images/2fa-rocket.svg';
import { sendOtp } from '../../authHelper';
import SubmitButton from '../../../Common/Buttons/SubmitButton';
import { constants } from '../../../../config';
import ShakeContainer from '../../ShakeContainer';
import errorCode from '../../../../constants/errorCode';
import AlertCode from '../../../../constants/alertCodes';

function EmailOTPMFAVerify() {
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isClickable, setIsClickable] = useState(true);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [errorObj, setErrorObj] = useState({ error: false });
  const [buttonText, setButtonText] = useState('Resend');
  const isDisabled = otp === '';
  const resendOTPTime = parseInt(process.env.RESEND_OTP_TIME_IN_SECONDS, 10)
    || constants.resendOTPTimeInSeconds;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showCustomAlert = useShowCustomAlert();
  const email = window.sessionStorage.getItem('email');

  const setError = (error) => setErrorObj((prev) => ({ ...prev, error }));

  const setErrorMessage = (message) => setErrorObj((prev) => ({ ...prev, message }));

  const handleClick = async () => {
    if (isClickable) {
      await sendOtp(setError, setErrorMessage);
      setSecondsRemaining(resendOTPTime);
      // change the text to sent after it is clicked
      setButtonText('Sent!');
      setTimeout(() => {
        setButtonText('Resend');
        setIsClickable(false);
      }, 800);
    }
  };

  const loginUser = async (password) => {
    try {
      await loginAPI(email, password);
      dispatch(updateIsAuthenticated(true));
      navigate('/home');
    } catch (err) {
      setErrorObj({ error: true, message: 'Server Error. Please Login Again' });
      setTimeout(() => {
        navigate('/');
      }, 2000);
      Sentry.captureException(`${errorCode.LOGIN_ERROR} - ${err}`, {
        extra: {
          errorMessage: AlertCode.INVALID_LOGIN_CREDENTIALS,
        },
      });
    } finally {
      window.sessionStorage.clear();
    }
  };

  const verifyOTP = async () => {
    try {
      setErrorObj({ error: false });
      if (!otp || otp.length < 6) {
        setErrorObj({ error: true, message: 'Please enter a valid OTP' });
        return;
      }
      setIsLoading(true);
      await verifyEmailOtpAPI({ otp });
      showCustomAlert({ message: 'OTP verified successfully' });
      const password = window.sessionStorage.getItem('password');
      const redirectUrl = window.sessionStorage.getItem('redirectUrl');
      if (redirectUrl) {
        window.sessionStorage.clear();
        window.location.href = redirectUrl;
        return;
      }
      if (email && password) {
        await loginUser(password);
      } else {
        setErrorObj({ error: true, message: 'Server Error. Please Login Again' });
        setTimeout(() => {
          navigate('/');
        }, 2000);
      }
      setIsLoading(false);
    } catch (err) {
      let errorMessage = 'Server Error';
      setIsLoading(false);
      const code = get(err, 'response.data.code');
      if (code === 'mfa/otp-invalid') {
        errorMessage = 'Invalid OTP';
        setErrorObj({ error: true, message: errorMessage });
        return;
      }
      if (code === 'mfa/invalid-token' || code === 'mfa/expired-token') {
        errorMessage = 'MFA Session expired';
        setErrorObj({ error: true, message: errorMessage });
        return;
      }
      Sentry.captureException(`${errorCode.LOGIN_ERROR} - ${err}`, {
        extra: {
          errorMessage,
        },
      });
      setErrorObj({ error: true, message: errorMessage });
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      verifyOTP();
    }
  };

  useEffect(() => {
    let intervalId;
    if (!isClickable && secondsRemaining > 0) {
      intervalId = setInterval(() => {
        setSecondsRemaining((prevSeconds) => {
          if (prevSeconds === 1) {
            setIsClickable(true);
            clearInterval(intervalId);
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isClickable, secondsRemaining]);

  useEffect(() => {
    setSecondsRemaining(resendOTPTime);
    setIsClickable(false);
  }, []);

  return (
    <div className="onboard-page">
      <img src={logo} alt="logo" className="hv-logo" />
      <ShakeContainer isError={errorObj?.error}>
        <h2 className="forget-pass-font">Two-Factor Authentication</h2>
        <div className="column-display">
          <img className="mfa-rocket-icon" src={MFArocket} alt=">" />
        </div>
        <p className="recovery-mail-text">We&apos;ve sent a code on your mail.</p>
        <p className="email-text">{email}</p>
        <input
          id="input-box"
          className="margin-top"
          type="number"
          placeholder="Paste email code"
          onChange={(e) => setOtp(e.target.value)}
          onKeyDown={handleEnterPress}
          required
        />
        <div className="column-display">
          <button
            type="button"
            className="resend-email"
            onClick={handleClick}
            disabled={!isClickable}
          >
            <span>Did not receive the mail?</span>
            {` ${buttonText} ${
              (isClickable) ? '' : `in ${secondsRemaining} sec`
            }`}
          </button>
        </div>
        <div>
          <SubmitButton
            isValid={!isDisabled}
            isLoading={isLoading}
            handleSubmit={verifyOTP}
            buttonText="Continue"
          />
          {errorObj?.error && (
          <div className="invalid-field display-center">
            <img className="alert-icon" src={alert} alt="!" />
            <span>{errorObj?.message}</span>
          </div>
          )}
          <div className="back-to-login-btn">
            <button
              type="submit"
              onClick={() => navigate('/')}
            >
              Back to login
            </button>
          </div>
        </div>
      </ShakeContainer>
    </div>
  );
}

export default EmailOTPMFAVerify;
