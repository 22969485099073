import React from 'react';
import * as Sentry from '@sentry/react';

import PropTypes from 'prop-types';
import { get } from 'lodash';
import Title from '../Title';
import TableRow from './TableRow';

const getConditionalData = ({
  dataToUse,
  conditional,
  condition,
}) => {
  if (!conditional) return dataToUse;

  try {
    const conditionSplit = condition.split(' === ');
    const conditionLHS = conditionSplit[0];
    const conditionRHS = conditionSplit[1].replace(/'/g, '');

    if (conditionLHS.includes('*')) {
      const conditionArrayPath = conditionLHS.split('.*.')[0];
      const conditionKey = conditionLHS.split('.*.')[1];

      const conditionArray = conditionArrayPath ? get(dataToUse, conditionArrayPath) : dataToUse;

      const result = conditionArray.filter((item) => get(item, conditionKey) === conditionRHS);

      return result;
    }

    return dataToUse;
  } catch (error) {
    Sentry.captureException(`Generic card v2 conditional utils. ${error}`);
    return dataToUse;
  }
};

const generateTableRows = ({ tableDataConfig, dataToUse }) => {
  const rows = tableDataConfig.reduce((aggregator, {
    path,
    label,
    persist,
    conditional,
    condition,
  }) => {
    const dataObject = getConditionalData({
      conditional,
      condition,
      dataToUse,
    });

    const value = get(dataObject, path);

    if (value || persist) {
      aggregator.push({
        label,
        value,
        persist,
        key: path,
      });
    }

    return aggregator;
  }, []);

  return rows;
};

function Table({
  tableDataConfig,
  title,
  responseData,
  requestData,
  id,
  elementKey,
  useRequestData,
  componentStyles,
  gridRow,
  gridCol,
}) {
  const dataToUse = useRequestData ? requestData : responseData;

  const tableRows = generateTableRows({ tableDataConfig, dataToUse });

  if (!tableRows || tableRows.length < 1) {
    return null;
  }

  const customStyles = {
    ...componentStyles,
    ...(gridRow && gridCol ? { gridArea: `${gridRow} / ${gridCol}` } : {}),
  };

  return (
    <div data-hj-suppress style={customStyles} key={elementKey} id={id} className="generic_common_card__tab__body__table__container">
      <Title text={title} />
      <div className="generic_common_card__tab__body__table">
        <table>
          {tableRows.map(({
            value, label, persist, key,
          }) => (
            <TableRow
              key={key}
              value={value}
              label={label}
              persist={persist}
            />
          ))}
        </table>
      </div>
    </div>
  );
}

Table.defaultProps = {
  useRequestData: false,
  componentStyles: {},
  gridRow: undefined,
  gridCol: undefined,
};

Table.propTypes = {
  tableDataConfig: PropTypes.array.isRequired,
  responseData: PropTypes.object.isRequired,
  requestData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  elementKey: PropTypes.string.isRequired,
  useRequestData: PropTypes.bool,
  componentStyles: PropTypes.object,
  gridRow: PropTypes.string,
  gridCol: PropTypes.string,
};

export default Table;
