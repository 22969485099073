import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { PermissionWrapper } from 'storybook-ui-components';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import * as Sentry from '@sentry/react';
import CredentialTable from './CredentialTable';
import AlertCode from '../../../constants/alertCodes';
import useShowErrorAlert from '../../../utils/lib';
import './Credential.scss';
import useGetUserPermissions from '../../../Permissions/hooks';
import getPermission from '../../../Permissions/mapping';
import CredentialSelectWrapper from '../../Common/Credentials/CredentialSelectWrapper';
import errorCode from '../../../constants/errorCode';

axios.defaults.withCredentials = true;

function Credential() {
  const showErrorAlert = useShowErrorAlert();
  const [credentials, setCredentials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const clientIdGroupedAppIds = useSelector((state) => state.user
    .credState?.credMapping);

  const currentClientId = useSelector((state) => state.user.credState?.current?.clientId);
  const currentUseCase = useSelector((state) => state.user.credState?.current?.useCase);

  const useCaseAppIds = get(clientIdGroupedAppIds, [currentClientId, currentUseCase], []);

  const fetchCredentials = async () => {
    setIsLoading(true);
    try {
      const credRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/credentials`,
      });
      setCredentials(credRes.data.result.credentials);
    } catch (error) {
      Sentry.captureException(`${errorCode.CREDENTAIL_FETCH_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.FETCH_USER_CREDENTIALS,
        },
      });
      showErrorAlert({ error, message: AlertCode.FETCH_USER_CREDENTIALS });
    }
    setIsLoading(false);
  };

  const getData = () => {
    const data = [];
    if (credentials) {
      credentials.forEach((elem) => {
        if (useCaseAppIds.find(({ appId }) => appId === elem.appId)) {
          data.push(elem);
        }
      });
    }
    return data;
  };
  useEffect(() => {
    fetchCredentials();
  }, []);

  return (
    <div id="main__container__body__generic">
      <div id="credential__heading_container">
        <p id="credential__heading">
          Credentials
        </p>
        <CredentialSelectWrapper hideAppIdDropdown id="credential__heading__creds" />
      </div>
      <div id="credential__table_container">
        <CredentialTable data={getData()} isLoading={isLoading} />
      </div>
    </div>
  );
}

export default PermissionWrapper(Credential, useGetUserPermissions, getPermission('credentialPage'));
