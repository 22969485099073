import * as Sentry from '@sentry/react';
import axios from 'axios';
import store from '../store';
import errorCode from '../constants/errorCode';

const storeAnalyticsMetadata = async (obj, table = 'dashboard', event = 'track_events') => {
  try {
    const state = store.getState();
    const { role: userRole } = state.user;

    const data = {
      project: table,
      properties: {
        ...obj,
        hv_event_name: event,
        env: process.env.REACT_APP_ENV,
        userRole,
      },
    };

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/analytics/logToRedshift`,
      data,
      withCredentials: false,
    });
  } catch (err) {
    Sentry.captureException(`${errorCode.RUDDERSTACK_ERROR} - ${err}`);
  }
};

export default storeAnalyticsMetadata;
