import React from 'react';
import PropTypes from 'prop-types';
import CreatedAtAndStatus from '../../CreatedAtAndStatus';

function DbCheckPanel({
  value, index, item, createdAt, status,
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <div id="details__tab__container">
        <CreatedAtAndStatus status={status} createdAt={createdAt} />
        <div id="details__tab__details__container">
          <div id="details__tab__details__image__container">
            <h1>{item.inputTitle}</h1>
            <div id="details__tab__details__body">
              {item.extractedReqData.map((ele, idx) => (
                <div key={ele} id="table__body">
                  <h2>{item.secTitle[idx]}</h2>
                  <div id="details__tab__details__body__table_container">
                    <table>
                      {ele.map((elem) => (
                        <tr key={elem.key}>
                          {elem.key === 'Error' ? <td data-hj-suppress className="err">{elem.value}</td> : ''}
                          <td className={`left ${elem.flag}`}>{elem.key === 'Error' ? '' : elem.key}</td>
                          <td data-hj-suppress className={`right ${elem.flag}`}>{elem.key !== 'Error' ? elem.value : ''}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                  <br />
                </div>
              ))}
            </div>
          </div>
          <div id="details__tab__details__body">
            <h1>{item.outputTitle}</h1>
            {item.extractedData.map((ele) => (
              <div key={ele}>

                <div id="details__tab__details__body__table_container">
                  <table>
                    {ele.map((elem) => (
                      <tr key={elem.key}>
                        <td className={`left ${elem.flag}`}>{elem.key}</td>
                        <td data-hj-suppress className={`right ${elem.flag}`}>
                          {elem.value !== undefined ? (
                            <>
                              {elem.value.toString()}
                              <span className="conf">
                                low conf.
                              </span>
                            </>
                          )
                            : ''}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
                <br />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

DbCheckPanel.defaultProps = {
  status: '',
  createdAt: '',
};

DbCheckPanel.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  status: PropTypes.string,
  createdAt: PropTypes.string,
};

export default DbCheckPanel;
