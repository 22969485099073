import { get } from 'lodash';
import * as Sentry from '@sentry/react';
import downloadDataApi from '../../../api/audit';
import { fetchClientLogs, fetchLogsForUniqueTransaction } from '../../../api/usage';
import AlertCode from '../../../constants/alertCodes';
import errorCodes from '../../../constants/errorCode';

export const usageDownloadType = 'usageLogs';
export const auditDataDownloadType = 'auditData';
export const transactionDataDownloadType = 'transactionLogs';

const fetchUsageLogs = async (apiData, showAlert, showErrorAlert) => {
  const { startDate, appIdList } = apiData;
  try {
    const res = await fetchClientLogs(startDate, appIdList);
    const statusCode = get(res, 'status');
    const { url } = get(res, 'data.result', {});
    if (statusCode === 202) {
      showAlert({ alertSeverity: 'info', message: AlertCode.usageLogs.GENERATING_TRY_AGAIN_LATER });
      return;
    }
    if (!url) {
      showAlert({ alertSeverity: 'success', message: AlertCode.usageLogs.EMPTY_DATA });
      return;
    }
    showAlert({ alertSeverity: 'success', message: AlertCode.usageLogs.GENERATED });
    window.open(url, '_blank');
  } catch (err) {
    const statusCode = get(err, 'response.status');
    if (statusCode === 404) {
      Sentry.captureException(`${errorCodes.USAGE_NO_LOGS} - ${err}`, {
        extra: {
          errorMessage: AlertCode.usageLogs.EMPTY_DATA,
        },
      });

      showErrorAlert({ err, message: AlertCode.usageLogs.EMPTY_DATA });
      return;
    }
    if (statusCode === 409) {
      Sentry.captureException(`${errorCodes.USAGE_GENERATE_TRY_AGAIN_LATER} - ${err}`, {
        extra: {
          errorMessage: AlertCode.usageLogs.GENERATING_TRY_AGAIN_LATER,
        },
      });
      showAlert({ alertSeverity: 'info', message: AlertCode.usageLogs.GENERATING_TRY_AGAIN_LATER });
      return;
    }
    Sentry.captureException(`${errorCodes.USAGE_INTERNAL_ERROR} - ${err}`, {
      extra: {
        errorMessage: AlertCode.usageLogs.UNKNOWN_ERROR,
      },
    });
    showErrorAlert({ err, message: AlertCode.usageLogs.UNKNOWN_ERROR });
  }
};

const fetchTransactionLogs = async (apiData, showAlert, showErrorAlert) => {
  try {
    const { startDate, appIdList } = apiData;
    const res = await fetchLogsForUniqueTransaction(startDate, appIdList);
    const statusCode = get(res, 'status');
    const { url } = get(res, 'data.result', {});
    if (statusCode === 202) {
      showAlert({ alertSeverity: 'info', message: AlertCode.usageLogs.GENERATING_TRY_AGAIN_LATER });
      return;
    }
    if (!url) {
      Sentry.captureException(`${errorCodes.TRANSACTION_INTERNAL_ERROR}`, {
        extra: {
          errorMessage: AlertCode.usageLogs.UNKNOWN_ERROR,
        },
      });
      showAlert({ alertSeverity: 'error', message: AlertCode.usageLogs.UNKNOWN_ERROR });
      return;
    }
    showAlert({ alertSeverity: 'success', message: AlertCode.usageLogs.GENERATED });
    window.open(url, '_blank');
  } catch (err) {
    const statusCode = get(err, 'response.status');
    if (statusCode === 404) {
      Sentry.captureException(`${errorCodes.TRANSACTION_NO_LOGS} - ${err}`, {
        extra: {
          errorMessage: AlertCode.usageLogs.EMPTY_DATA,
        },
      });

      showErrorAlert({ err, message: AlertCode.usageLogs.EMPTY_DATA });
      return;
    }
    if (statusCode === 409) {
      Sentry.captureException(`${errorCodes.TRANSACTION_GENERATE_TRY_AGAIN_LATER} - ${err}`, {
        extra: {
          errorMessage: AlertCode.usageLogs.GENERATING_TRY_AGAIN_LATER,
        },
      });

      showAlert({ alertSeverity: 'info', message: AlertCode.usageLogs.GENERATING_TRY_AGAIN_LATER });
      return;
    }
    Sentry.captureException(`${errorCodes.TRANSACTION_INTERNAL_ERROR} - ${err}`, {
      extra: {
        errorMessage: 'Error fetching transaction logs',
      },
    });
    showErrorAlert({ err, message: 'Error fetching transaction logs' });
  }
};

const fetchAuditData = async (apiData, showAlert, showErrorAlert) => {
  try {
    showAlert({ alertSeverity: 'info', message: AlertCode.auditDataDownload.GENERATING });
    const {
      searchValue, startTime, endTime, metaData, currentAppId, status,
    } = apiData;
    const valueMetaData = {};
    Object.keys(metaData).forEach((key) => {
      valueMetaData[key] = metaData[key].value;
    });
    const { data } = await downloadDataApi(
      searchValue,
      status,
      startTime,
      endTime,
      valueMetaData,
      currentAppId,
    );
    showAlert({ alertSeverity: 'success', message: AlertCode.auditDataDownload.GENERATED });
    const { url } = data.result;
    window.open(url, '_blank');
  } catch (err) {
    const statusCode = get(err, 'response.status');
    const errorCode = get(err, 'response.data.error.code');
    if (statusCode === 400 && errorCode === 'NO_DATA') {
      Sentry.captureException(`${errorCodes.AUDIT_NO_LOGS} - ${err}`, {
        extra: {
          errorMessage: AlertCode.auditDataDownload.EMPTY_DATA,
        },
      });
      showErrorAlert({ err, message: AlertCode.auditDataDownload.EMPTY_DATA });
      return;
    }
    if (statusCode === 400 && errorCode === 'LIMIT_EXCEEDED') {
      Sentry.captureException(`${errorCodes.AUDIT_SIZE_LIMIT_EXCEEDED} - ${err}`, {
        extra: {
          errorMessage: AlertCode.auditDataDownload.LIMIT_EXCEEDED,
        },
      });
      showErrorAlert({ err, message: AlertCode.auditDataDownload.LIMIT_EXCEEDED });
      return;
    }
    Sentry.captureException(`${errorCodes.AUDIT_INTERNAL_ERROR} - ${err}`, {
      extra: {
        errorMessage: AlertCode.auditDataDownload.UNKNOWN_ERROR,
      },
    });
    showErrorAlert({ err, message: AlertCode.auditDataDownload.UNKNOWN_ERROR });
  }
};

const downloadApiMapping = {
  [usageDownloadType]: fetchUsageLogs,
  [auditDataDownloadType]: fetchAuditData,
  [transactionDataDownloadType]: fetchTransactionLogs,
};

export default downloadApiMapping;
