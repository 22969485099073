import React from 'react';
import PropTypes from 'prop-types';
import { formatDateForApplicationTable } from '../../../../../utils/dateFormats';
import showApiStatus from '../../../../../constants/showApiStatus';

function CommonTabHeader({
  createdAt, status, statusText,
}) {
  return (
    <div className="generic_common_card__tab__header">
      <p>
        Attempted at:
        <span>{formatDateForApplicationTable(createdAt)}</span>
      </p>
      { status
        ? (
          <p>
            {statusText}
            :
            <span className={`generic_common_card__tab__header__status__${showApiStatus(status).style}`}>{showApiStatus(status).status}</span>
          </p>
        ) : ''}
    </div>
  );
}
CommonTabHeader.defaultProps = {
  status: '',
  createdAt: '',
  statusText: 'Summary',
};

CommonTabHeader.propTypes = {
  status: PropTypes.string,
  createdAt: PropTypes.string,
  statusText: PropTypes.string,
};
export default CommonTabHeader;
