import { isString } from 'lodash';

const showApiStatus = (val) => {
  const statusInfo = {};
  if (!isString(val)) {
    return {
      status: 'reported by agent',
      style: 'reported',
    };
  }
  switch (val.toLowerCase()) {
    case 'pass':
      statusInfo.status = 'pass';
      statusInfo.style = 'approved';
      break;
    case 'fail':
      statusInfo.status = 'fail';
      statusInfo.style = 'declined';
      break;
    case 'manualreview':
      statusInfo.status = 'needs review';
      statusInfo.style = 'review';
      break;
    case 'retake':
      statusInfo.status = 'retake';
      statusInfo.style = 'dropped';
      break;
    case 'unexpectederror':
      statusInfo.status = 'Unexpected Error';
      statusInfo.style = 'dropped';
      break;
    // To be changed later based on response
    case 'potentialmatch':
      statusInfo.status = 'Potential Match';
      statusInfo.style = 'review';
      break;
    case 'high':
      statusInfo.status = 'High';
      statusInfo.style = 'declined';
      break;
    default:
      statusInfo.status = 'reported by agent';
      statusInfo.style = 'reported';
      break;
  }
  return statusInfo;
};

export default showApiStatus;
