import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import '../../Details.scss';
import '../../Details.css';
import {
  Timeline,
} from '@mui/lab';
import CreatedAtAndStatus from '../../CreatedAtAndStatus';
import VideoPlayer from './VideoPlayer';
import TimelineCard from './TimelineCard';

const videoDefaultKeys = ['video', 'recording'];

const VideoCardPanel = ({ item, value, index }) => {
  const videoKeys = get(item, 'endpointModule.videoTypes', videoDefaultKeys);
  const videoType = item.fileUrls.find((elem) => videoKeys.includes(elem.type));
  const videoSrc = get(videoType, 'url');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('No Video Found!');
  const { statements, images, videoRef: journeyId } = item.extractedData.details;
  const videoPlayerRef = useRef();

  return (
    <div role="tabpanel" id="details__tab__video__container" hidden={value !== index}>
      <CreatedAtAndStatus createdAt={item?.createdAt} status={item?.status} />
      {!isError && videoSrc ? (
        <div id="video_details_row">
          <div id="video_details_col">
            <VideoPlayer
              videoSrc={videoSrc}
              setIsError={setIsError}
              setErrorMessage={setErrorMessage}
              videoPlayerRef={videoPlayerRef}
              statements={statements}
              journeyId={journeyId}
              activeAttemptIndex={value}
              attemptIndex={index}
            />
          </div>
          <div id="video_details_col">
            <p id="video_details_col_title">VERIFICATION RESULT</p>
            <div id="video_details_col_timeline">
              <Timeline>
                {
                  statements
                  && statements.map(
                    (stmt, idx) => (
                      <TimelineCard
                        key={stmt.statementId}
                        statement={stmt}
                        index={idx}
                        images={images}
                        videoPlayerRef={videoPlayerRef}
                        journeyId={journeyId}
                      />
                    ),
                  )
                }
              </Timeline>
            </div>
          </div>
        </div>
      ) : <p id="details__tab__video__error">{errorMessage}</p>}
    </div>
  );
};

VideoCardPanel.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default VideoCardPanel;
