import React from 'react';
import {
  TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator,
} from '@mui/lab';
import { ToolTip } from 'clm-components';

import PropTypes from 'prop-types';

import { startCase } from 'lodash';
import { ReactComponent as DotIcon } from '../../../../assests/icons/dot.svg';
import { ReactComponent as FlagIcon } from '../../../../assests/icons/flag.svg';
import { getOrdinalSuffix } from '../../RecordUtils';

function TimelineViewItem({
  name, time, attempt, status, createdAt, flag, end,
}) {
  const displayTooltip = () => {
    const tooltip = document.getElementById(`timeline_tooltip_${name}_${attempt}`);
    tooltip?.classList?.add('active');
  };

  const hideTooltip = () => {
    const tooltip = document.getElementById(`timeline_tooltip_${name}_${attempt}`);
    tooltip?.classList?.remove('active');
  };

  return (
    <TimelineItem data-date={createdAt} className="application_details__timeline_view__item__container">
      <TimelineContent className="application_details__timeline_view__timeline_content__time">
        <div className="application_details__timeline_view__timeline_content__time_container">
          <p>{time}</p>
        </div>
      </TimelineContent>
      <TimelineSeparator className="application_details__timeline_view__separator">
        <TimelineDot />
        {end ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent className="application_details__timeline_view__timeline_content__module">
        <div className="application_details__timeline_view__timeline_content__module_container">
          <p>
            {name}
            <DotIcon className="application_details__timeline_view__timeline_content__module_dot_icon" />
            <span>
              {getOrdinalSuffix(attempt)}
              {' '}
              attempt
            </span>
            {status === 'fail' && flag && (
            <>
              <ToolTip title={flag} placement="top">
                <FlagIcon onMouseEnter={displayTooltip} onMouseLeave={hideTooltip} className="application_details__timeline_view__timeline_content__module_flag_icon" />
              </ToolTip>
            </>
            )}
          </p>
          <div className={`application_details__timeline_view__timeline_content__module_status ${status}`}>
            <p>{startCase(status)}</p>
          </div>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
}

TimelineViewItem.defaultProps = {
  flag: '',
  end: false,
};

TimelineViewItem.propTypes = {
  name: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  attempt: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  flag: PropTypes.string,
  end: PropTypes.bool,
};

export default TimelineViewItem;
