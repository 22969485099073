/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import CreatedAtAndStatus from '../../CreatedAtAndStatus';
import ImageViewerOpenWrapper from '../../../Common/Image/ImageViewer/ImageViewerOpenWrapper';
import PDFViewerOpenWrapper from '../../../Common/PDF/PDFViewer/PDFViewerOpenWrapper';
import MediaWrapper from '../MediaWrapper/MediaWrapper';
import seperatePDFsAndImages from '../MediaWrapper/utils';

const image1DefaultKeys = ['selfie', 'image1'];
const image2DefaultKeys = ['id', 'image2', 'idFaceString'];
function FaceMatchPanel({
  value, index, item, createdAt, status,
}) {
  const [slideShowImageIndex, setSlideShowImageIndex] = useState(0);
  const [pdfIndex, setPdfIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false);

  const image1Keys = get(item, 'endpointModule.image1Keys', image1DefaultKeys);
  const image2Keys = get(item, 'endpointModule.image2Keys', image2DefaultKeys);

  const image1Type = item.fileUrls.find((elem) => image1Keys.includes(elem.type))
  || get(item, 'fileUrls[0]');
  const image2Type = item.fileUrls.find((elem) => image2Keys.includes(elem.type))
  || get(item, 'fileUrls[1]');

  const image1Src = get(image1Type, 'url');
  const image2Src = get(image2Type, 'url')
  || (get(item, 'base64Image[0]')
    ? `data:image/jpeg;base64,${item.base64Image[0]}`
    : null);

  const { images: viewerImages, pdfs } = seperatePDFsAndImages([
    { src: image1Src, label: item.inputTitle },
    { src: image2Src, label: item.inputTitle1 },
  ]);

  const handleImageClick = (imageIndex) => {
    setSlideShowImageIndex(imageIndex);
    setIsViewerOpen(true);
  };

  const handlePDFClick = (pdfIndexToSet) => {
    setPdfIndex(pdfIndexToSet);
    setIsPDFViewerOpen(true);
  };

  const handleViewerClose = () => {
    setIsViewerOpen(false);
  };

  const handlePDFViewerClose = () => {
    setIsPDFViewerOpen(false);
  };

  return (
    <>
      <ImageViewerOpenWrapper
        defaultImageIndex={slideShowImageIndex}
        images={viewerImages}
        open={isViewerOpen}
        closeViewerHandler={handleViewerClose}
      />
      <PDFViewerOpenWrapper
        open={isPDFViewerOpen}
        pdf={get(pdfs, pdfIndex, {})}
        closeViewerHandler={handlePDFViewerClose}
      />
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        <div id="details__tab__container">
          <CreatedAtAndStatus status={status} createdAt={createdAt} />
          <div id="details__tab__details__container">
            <div id="details__tab__details__image__container">
              <h1>{item.inputTitle}</h1>
              {image1Src ? (
                <MediaWrapper
                  id="details__tab__details__image"
                  src={image1Src}
                  onImageClick={() => handleImageClick(0)}
                  onPDFClick={() => handlePDFClick(0)}
                />
              ) : <div id="details__tab__details__image" />}
            </div>
            <div id="details__tab__details__image__container">
              <h1>{item.inputTitle1}</h1>
              {image2Src ? (
                <MediaWrapper
                  id="details__tab__details__image"
                  src={image2Src}
                  onImageClick={() => handleImageClick(viewerImages.length === 1 ? 0 : 1)}
                  onPDFClick={() => handlePDFClick(pdfs.length === 1 ? 0 : 1)}
                />
              ) : <div id="details__tab__details__image" />}
            </div>
            <div id="details__tab__details__body">
              <h1>{item.outputTitle}</h1>
              {item.extractedData.map((ele, idx) => (
                <div id="table__body">
                  <h2>{item.secTitle[idx]}</h2>
                  <div id="details__tab__details__body__table_container">
                    <table>
                      {ele.map((elem) => (
                        <tr key={elem.key}>
                          {elem.key === 'Error' ? <td className="err">{elem.value}</td> : ''}
                          <td className={`left ${elem.flag}`}>{elem.key === 'Error' ? '' : elem.key}</td>
                          <td data-hj-suppress className={`right ${elem.flag}`}>
                            {elem.key !== 'Error' ? (
                              <>
                                {elem.value}
                                <span className="conf">
                                  low conf.
                                </span>
                              </>
                            )
                              : ''}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                  <br />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

FaceMatchPanel.defaultProps = {
  status: '',
  createdAt: '',
};

FaceMatchPanel.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  status: PropTypes.string,
  createdAt: PropTypes.string,
};

export default FaceMatchPanel;
