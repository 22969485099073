import * as Sentry from '@sentry/react';
import axios from 'axios';
import { get, keys } from 'lodash';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1/iam`,
  withCredentials: true,
});

const getReviewerListApi = async ({ appId }) => {
  try {
    const res = await axiosRequest.get('/reviewers', {
      headers: {
        appId,
      },
    });

    const reviewersList = get(res, 'data.result');
    return keys(reviewersList).reduce((acc, role) => {
      acc.push({ role, emails: reviewersList[role].map((email) => ({ id: email })) });
      return acc;
    }, []);
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export default getReviewerListApi;
