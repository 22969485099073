/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import { get } from 'lodash';
import Section from './Section';
import Row from './Row';
import Column from './Column';
import Table from './Table';
import Media from './Media';
import Accordion from './Accordion';
import Grid from './Grid';
import VideoPlayer from './VideoPlayer';

const getConditionalData = ({
  condition,
  responseData,
  requestData,
  useRequestData,
}) => {
  try {
    const conditionSplit = condition.split(' === ');
    const conditionLHS = conditionSplit[0];
    const conditionRHS = conditionSplit[1].replace(/'/g, '');
    const dataToUse = useRequestData ? requestData : responseData;

    if (conditionLHS.includes('*')) {
      const conditionArrayPath = conditionLHS.split('.*.')[0];
      const conditionKey = conditionLHS.split('.*.')[1];

      const conditionArray = conditionArrayPath ? get(dataToUse, conditionArrayPath) : dataToUse;

      const result = conditionArray.filter((item) => get(item, conditionKey) === conditionRHS);

      return {
        response: useRequestData ? responseData : result,
        request: useRequestData ? result : requestData,
      };
    }

    return { request: requestData, response: responseData };
  } catch (error) {
    Sentry.captureException(`Generic card v2 conditional utils. ${error}`);
    return { request: requestData, response: responseData };
  }
};

const GenericComponent = ({
  componentConfig,
  responseData,
  requestData,
  moduleId,
  index,
  elementKey,
}) => {
  const {
    type,
    id,
    styles,
    conditional,
    condition,
    gridRow,
    gridCol,
    useRequestData,
    children,
  } = componentConfig;
  const elementId = `${moduleId}_${id}_${index}`;

  let finalResponseData = responseData;
  let finalRequesData = requestData;

  if (conditional) {
    const { response, request } = getConditionalData({
      condition,
      responseData,
      requestData,
      useRequestData,
    });

    finalResponseData = response;
    finalRequesData = request;
  }

  const childrenComponents = children?.map((child, childIndex) => (
    <GenericComponent
      key={elementKey}
      componentConfig={child}
      requestData={finalRequesData}
      responseData={finalResponseData}
      useRequestData={useRequestData}
      moduleId={moduleId}
      index={index}
      elementKey={`${elementId}_${childIndex}`}
    />
  ));

  switch (type) {
    case 'section':
      return (
        <Section
          elementKey={elementKey}
          id={elementId}
          componentStyles={styles}
          gridRow={gridRow}
          gridCol={gridCol}
        >
          {childrenComponents}
        </Section>
      );

    case 'row':
      const { title: rowTitle } = componentConfig;
      return (
        <Row
          elementKey={elementKey}
          id={elementId}
          title={rowTitle}
          componentStyles={styles}
          gridRow={gridRow}
          gridCol={gridCol}
        >
          {childrenComponents}
        </Row>
      );

    case 'column':
      const {
        title: columnTitle,
      } = componentConfig;

      return (
        <Column
          elementKey={elementKey}
          id={elementId}
          title={columnTitle}
          componentStyles={styles}
          gridRow={gridRow}
          gridCol={gridCol}
        >
          {childrenComponents}
        </Column>
      );

    case 'grid':
      const { gridColumnsSplit } = componentConfig;
      return (
        <Grid
          gridColumnsSplit={gridColumnsSplit}
          elementKey={elementKey}
          id={elementId}
          componentStyles={styles}
        >
          {childrenComponents}
        </Grid>
      );

    case 'accordion':
      const {
        title: accordionTitle,
        contentStyles,
        maxContentHeight,
      } = componentConfig;
      return (
        <Accordion
          componentStyles={styles}
          contentStyles={contentStyles}
          title={accordionTitle}
          maxContentHeight={maxContentHeight}
          gridRow={gridRow}
          gridCol={gridCol}
        >
          {childrenComponents}
        </Accordion>
      );

    case 'table':
      const {
        tableDataConfig,
        title,
      } = componentConfig;

      return (
        <Table
          elementKey={elementKey}
          id={elementId}
          tableDataConfig={tableDataConfig}
          title={title}
          requestData={finalRequesData}
          responseData={finalResponseData}
          useRequestData={useRequestData}
          componentStyles={styles}
          gridRow={gridRow}
          gridCol={gridCol}
        />
      );

    case 'media':
      const {
        label: mediaLabel,
        mediaPath,
        persistMedia,
        hideLabel,
      } = componentConfig;

      return (
        <Media
          elementKey={elementKey}
          id={elementId}
          mediaLabel={mediaLabel}
          mediaPath={mediaPath}
          requestData={finalRequesData}
          responseData={finalResponseData}
          persistMedia={persistMedia}
          useRequestData={useRequestData}
          componentStyles={styles}
          hideLabel={hideLabel}
          gridRow={gridRow}
          gridCol={gridCol}
        />
      );

    case 'video':
      const {
        label: videoLabel,
        videoPath,
      } = componentConfig;

      return (
        <VideoPlayer
          elementKey={elementKey}
          id={elementId}
          videoLabel={videoLabel}
          videoPath={videoPath}
          requestData={finalRequesData}
          responseData={finalResponseData}
          useRequestData={useRequestData}
          componentStyles={styles}
          gridRow={gridRow}
          gridCol={gridCol}
        />
      );

    default:
      return null;
  }
};

export const recursivelyIterateAndClearEmptyNodes = (nodeId) => {
  if (!nodeId) return;

  const element = document.getElementById(nodeId);

  element?.children?.forEach((child) => {
    const childId = child?.id;
    recursivelyIterateAndClearEmptyNodes(childId);
  });

  if (element?.children?.length === 0) {
    element?.parentNode?.removeChild(element);
  }
};

GenericComponent.propTypes = {
  componentConfig: PropTypes.object.isRequired,
  responseData: PropTypes.object.isRequired,
  requestData: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  elementKey: PropTypes.string.isRequired,
};

export default GenericComponent;
