import React from 'react';
import PropTypes from 'prop-types';
import { formatDateForApplicationTable } from '../../utils/dateFormats';
import showApiStatus from '../../constants/showApiStatus';

function CreatedAtAndStatus({
  createdAt, status, statusText,
}) {
  return (
    <div id="details__tab__header">
      <p>
        Attempted at:
        <span>{formatDateForApplicationTable(createdAt)}</span>
      </p>
      { status
        ? (
          <p>
            {statusText}
            :
            <span id={`details__tab__header__status__${showApiStatus(status).style}`}>{showApiStatus(status).status}</span>
          </p>
        ) : ''}
    </div>
  );
}
CreatedAtAndStatus.defaultProps = {
  status: '',
  createdAt: '',
  statusText: 'Summary',
};

CreatedAtAndStatus.propTypes = {
  status: PropTypes.string,
  createdAt: PropTypes.string,
  statusText: PropTypes.string,
};
export default CreatedAtAndStatus;
