import React from 'react';
import { useSelector } from 'react-redux';
import vkycIcon from '../../../assests/images/VKYC.png';
import productRedirectMapping from '../../../utils/productRedirectMapping';
import arrow from '../../../assests/icons/arrowSVG.svg';
import './Home.scss';
import { useFormatAnalyticsData } from '../../../utils/lib';
import rudderstackEvents from '../../../constants/rudderstackEventNames';
import screenNames from '../../../constants/screenNames';

const VideoKycCard = () => {
  const formatAnalyticsData = useFormatAnalyticsData();
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);

  const redirectButtonClick = () => {
    formatAnalyticsData(
      email, clientId, rudderstackEvents.DASHBOARD_VKYC_LINK_CLICK, screenNames.HOME,
    );
    window.location.replace(productRedirectMapping.VKYCPortal);
  };

  return (
    <div id="vkyc_card">
      <div id="vkyc_card__icon_container">
        <img src={vkycIcon} alt="vkyc" />
        <div>
          <p id="vkyc_card__main_heading">Video KYC - Dashboard</p>
          <p id="vkyc_card__sub_heading">Audit applications, manage shifts, view VKYC analytics & more</p>
        </div>
      </div>
      <div id="vkyc_card__action_text_container">
        <button onClick={redirectButtonClick} type="button" id="vkyc_card__action_text">
          Access VCIP dashboard
          <img src={arrow} alt="arrow" className="modal__link_arrow" />
        </button>
      </div>
    </div>
  );
};

export default VideoKycCard;
