/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import NotFound from './containers/NotFound';
import Record from './containers/Record/RecordWrapper';
import Login from './components/Onboarding/Login';
import PrivateRoute from './utils/PrivateRoute';
import Application from './components/Main/Application';
import Credential from './components/Main/Credential/Credentials';
import Home from './components/Main/Home';
import Verify from './containers/Verify';
import Main from './containers/Main';
import InternalLogin from './containers/InternalLogin';
import GenericRedirect from './containers/GenericRedirect';
import Onboarding from './containers/Onboarding';
import Signup from './components/Onboarding/Signup';
import { useHJInitScript, useFCInitScript } from './utils/intialization';
import ResetPassword from './components/Onboarding/ResetPassword';
import ResetPasswordEmailSent from './components/Onboarding/ResetPasswordEmailSent';
import CreatePassword from './components/Onboarding/CreatePassword';
import CreatePasswordSuccessful from './components/Onboarding/CreatePasswordSuccessful';
import { persistor } from './store';
import Usage from './components/Account/Usage/Usage';
import ErrorBoundary from './components/ErrorBoundary';
import './index.css';
import DownloadFile from './containers/Common/Download/DownloadFileFromLink';
import MFA from './containers/MFA';
import MFAOptions from './components/Onboarding/MFA/MFAOptions';
import EmailOtpMFA from './components/Onboarding/MFA/EmailMFA/EmailOtpMFA';
import EmailOTPMFAVerify from './components/Onboarding/MFA/EmailMFA/EmailOTPMFAVerify';
import PricingInfo from './containers/PricingInfo/PricingInfo';
import { updateColumns } from './reducers/data';

const freshChatBlacklistRoutes = ['/demo', '/verify', '/record', '/view'];

const THEME = createTheme({
  typography: {
    fontFamily: '"Lexend", sans-serif',
    fontWeight: 300,
  },
});

function App() {
  const auth = useSelector((state) => state.user.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth) {
      persistor.purge();
      dispatch({ type: 'RESET' });
    } else {
      dispatch(updateColumns(
        JSON.parse(localStorage.getItem('selectedColumn')),
      ));
    }
  }, [auth]);

  useHJInitScript();

  useFCInitScript();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={THEME}>
        <ErrorBoundary>
          <div className="App">
            <DownloadFile />
            <Routes>
              <Route path="/">
                <Route path="sign-up" element={<Signup />} />
                <Route path="/" element={<Login />} />
                <Route path="password" element={<ResetPassword />} />
                <Route path="password/update" element={<ResetPasswordEmailSent />} />
                <Route path="password/create" element={<CreatePassword />} />
                <Route path="password/success" element={<CreatePasswordSuccessful />} />
              </Route>
              <Route path="mfa">
                {/* "TODO: re-add later if necessary" */}
                {/* <Route path="options" element={<MFAOptions />} />
                <Route path="email/otp" element={<EmailOtpMFA />} /> */}
                <Route path="email/otp/verify" element={<EmailOTPMFAVerify />} />
              </Route>
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Main />}>
                  {/* Add in util/navMapping.js when adding a new component inside main */}
                  <Route path="home" element={<Home />} />
                  <Route path="applications" element={<Application />} />
                  <Route path="review" element={<Application />} />
                  <Route path="credentials" element={<Credential />} />
                  <Route path="usage" element={<Usage />} />
                  <Route path="pricing" element={<PricingInfo />} />
                </Route>
                <Route path="record" element={<Record />} />
                <Route path="view" element={<Record />} />
              </Route>
              <Route path="demo" element={<Verify isDemo />} />
              <Route path="verify" element={<Verify isDemo={false} />} />
              <Route path="internal/login" element={<InternalLogin />} />
              <Route path="redirect" element={<GenericRedirect />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
