/* eslint-disable jsx-a11y/control-has-associated-label */
import Grid from '@mui/material/Grid';
import axios from 'axios';
import React, { useEffect, useState, useMemo } from 'react';
import { PermissionWrapper } from 'storybook-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import DataTable from './DataTable';
import AlertCode from '../../../constants/alertCodes';
import useShowErrorAlert, { useFormatAnalyticsData } from '../../../utils/lib';
import {
  updateData, updateTotalElements, updatePage,
  updateReviewersList,
} from '../../../reducers/data';
import { constants } from '../../../config';
import './Audit.scss';
import CommonSearchBar from '../../Common/Inputs/CommonSearchBar';
import AddFilter from '../../Common/Filter/AddFilter';
import EditFilter from '../../Common/Filter/EditFilter';
import RefreshIcon from '../../../assests/icons/refresh.png';
import { fetchFilterList, getTransactionMetadataFilters } from './AuditFilterUtils';
import TableActions from './TableActions/TableActions';
import TableActionsButton from './TableActions/TableActionsButton';
import CustomPopupContainer from '../../Common/Popup/CustomPopupContainer';
import useS3Config from '../../../utils/hooks/s3Config';
import useGetUserPermissions from '../../../Permissions/hooks';
import getPermission from '../../../Permissions/mapping';
import { applicationStatusLabelMap } from '../../../constants/applicationFilters';
import { isNonEmptyObject } from '../../../utils/helpers';
import rudderstackEvents from '../../../constants/rudderstackEventNames';
import screenNames from '../../../constants/screenNames';
import errorCode from '../../../constants/errorCode';
import getReviewerListApi from '../../../api/iam';

axios.defaults.withCredentials = true;

function Audit() {
  const dispatch = useDispatch();
  const showErrorAlert = useShowErrorAlert();
  const auditData = useSelector((state) => state.data.value);
  const status = useSelector((state) => state.data.status);
  const metaData = useSelector((state) => state.data.metaData);
  const sortBy = useSelector((state) => state.data.sortBy);
  const transactionMetadataFilters = useSelector(
    (state) => state?.data?.transactionMetadataFilters,
  );
  const valueMetaData = {};
  Object.keys(metaData).forEach((key) => {
    valueMetaData[key] = metaData[key].value;
  });
  const application = useSelector((state) => state.data.application);
  const { startTime, endTime } = application.value;
  const currentAppId = useSelector((state) => state.user.credState?.current?.appId);
  const totalElements = useSelector((state) => state.data.totalElements);
  const page = useSelector((state) => state.data.page);
  const workflowList = useSelector((state) => state.user.workflowIdListWithDbData);
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const [prevAppId, setPrevAppId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const location = useLocation();

  const [selectedFilterList, setSelectedFilterList] = useState([]);
  const [selectedFilterDetailedList, setSelectedFilterDetailedList] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const [availableFilterList, setAvailableFilterList] = useState([]);
  const [availableFilterListlength, setAvailableFilterListLength] = useState(0);
  const selectedTableColumns = useSelector((state) => state.data.selectedColumns);
  const landingPage = location.state?.page || 0;
  const searchInit = location.state?.searchId || '';
  const getFilterConfig = useS3Config('filterConfig');
  const getTransactionMetadataColumnsConfig = useS3Config('transactionMetadataColumnsConfig');
  const formatAnalyticsData = useFormatAnalyticsData();
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const fetchTableData = async () => {
    try {
      const formattedTransactionMetadataFilters = getTransactionMetadataFilters(
        transactionMetadataFilters,
      );
      const dataRes = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/audit/filter`,
        headers: { appId: currentAppId },
        data: {
          searchValue: searchKey,
          status: status.value,
          startTime,
          endTime,
          page,
          rows: constants.maxRecordsInTable,
          transactionMetadataFilters: formattedTransactionMetadataFilters,
          ...valueMetaData,
          ...(isNonEmptyObject(sortBy) ? { sortBy } : {}),
        },
      });

      dispatch(updateData(dataRes.data.result.data));
    } catch (error) {
      Sentry.captureException(`${errorCode.FETCH_APPLICATION_ERROR} - ${error}`, {
        extra: {
          errorMessage: 'Error Fetching Table Data',
        },
      });
      dispatch(updateData([]));
      dispatch(updateTotalElements(0));
      showErrorAlert({ error, message: AlertCode.FETCH_TABLE_DATA });
    }
    setLoading(false);
  };

  const fetchRowCount = async () => {
    try {
      const formattedTransactionMetadataFilters = getTransactionMetadataFilters(
        transactionMetadataFilters,
      );
      const res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/audit/count`,
        headers: { appId: currentAppId },
        data: {
          searchValue: searchKey,
          status: status.value,
          startTime,
          endTime,
          transactionMetadataFilters: formattedTransactionMetadataFilters,
          ...valueMetaData,
        },
      });
      if (res.data.result.count === 0 && prevAppId !== currentAppId) {
        setPrevAppId(currentAppId);
      }
      dispatch(updateTotalElements(res.data.result.count));
    } catch (error) {
      console.log('DEBUG', error);
      Sentry.captureException(`${errorCode.APPLICATION_FETCH_COUNT_ERROR} - ${error}`, {
        extra: {
          errorMessage: 'Error Fetching Row Count',
        },
      });
      showErrorAlert({ error, message: AlertCode.FETCH_ROW_COUNT });
    }
  };

  const handleDataChange = () => {
    setLoading(true);
    dispatch(updatePage(landingPage));
    location.state = undefined;
    fetchTableData();
    fetchRowCount();
  };

  const fetchReviewerList = async () => {
    if (currentAppId && currentAppId !== '') {
      const reviewersList = await getReviewerListApi({ appId: currentAppId });
      dispatch(updateReviewersList({ reviewersList }));
    }
  };

  useEffect(() => {
    if (currentAppId && currentAppId !== '') handleDataChange();
  }, [
    status,
    startTime,
    endTime,
    currentAppId,
    metaData,
    searchKey,
    sortBy,
    transactionMetadataFilters,
  ]);

  useEffect(() => {
    fetchReviewerList();
  }, [currentAppId]);

  const handlePageChange = async (event, newPage) => {
    setLoading(true);
    try {
      const formattedTransactionMetadataFilters = getTransactionMetadataFilters(
        transactionMetadataFilters,
      );
      const dataRes = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/audit/filter`,
        headers: { appId: currentAppId },
        data: {
          searchValue: searchKey,
          status: status.value,
          startTime,
          endTime,
          page: newPage - 1,
          rows: constants.maxRecordsInTable,
          transactionMetadataFilters: formattedTransactionMetadataFilters,
          ...valueMetaData,
          ...(isNonEmptyObject(sortBy) ? { sortBy } : {}),
        },
      });
      dispatch(updatePage(newPage - 1));
      dispatch(updateData(dataRes.data.result.data));
    } catch (error) {
      Sentry.captureException(`${errorCode.FETCH_TABLE_DATA} - ${error}`, {
        extra: {
          errorMessage: AlertCode.FETCH_TABLE_DATA,
        },
      });
      showErrorAlert({ error, message: AlertCode.FETCH_TABLE_DATA });
    }
    setLoading(false);
  };

  const handleRowClick = (row) => {
    formatAnalyticsData(
      email,
      clientId,
      rudderstackEvents.DASHBOARD_APPLICATIONS_APPLICATION_CLICK,
      screenNames.APPLICATIONS,
    );
    window.open(`/record?transactionId=${encodeURIComponent(row.transactionId.data.id)}&currentElement=${row.transactionId.data.rowId}&searchValue=${searchKey}`);
  };

  const onFilterAdd = (details) => {
    const { key, updateFuntion } = details;
    const { filterLabel, value } = details.genLabelFunc(details);
    setSelectedFilterList([...selectedFilterList, key]);
    setSelectedFilterDetailedList((oldList) => [
      ...oldList,
      {
        details,
        filterLabel,
      },
    ]);
    dispatch(updatePage(0));
    dispatch(updateFuntion(
      {
        data: { key, value },
        filterData: details,
      },
    ));
    formatAnalyticsData(
      email,
      clientId,
      rudderstackEvents.DASHBAORD_APPLICATIONS_FILTER_CHANGE,
      screenNames.APPLICATIONS,
    );
  };

  const onFilterEdit = (details, idxToEdit) => {
    const { key, updateFuntion } = details;
    const { filterLabel, value } = details.genLabelFunc(details);
    setSelectedFilterDetailedList((oldList) => oldList.map((filter, idx) => (idx !== idxToEdit
      ? filter
      : {
        details,
        filterLabel,
      })));
    dispatch(updatePage(0));
    dispatch(updateFuntion(
      {
        data: { key, value },
        filterData: details,
      },
    ));
    formatAnalyticsData(
      email,
      clientId,
      rudderstackEvents.DASHBAORD_APPLICATIONS_FILTER_CHANGE,
      screenNames.APPLICATIONS,
    );
  };

  const removeFilter = (details, idxToRemove) => {
    const { key: keyToRemove, updateFuntion } = details;
    setSelectedFilterList((oldList) => oldList.filter((key) => key !== keyToRemove));
    setSelectedFilterDetailedList((oldList) => oldList.filter(
      (filter, idx) => idx !== idxToRemove,
    ));
    dispatch(updatePage(0));
    dispatch(updateFuntion({ data: { key: keyToRemove } }));
    formatAnalyticsData(
      email,
      clientId,
      rudderstackEvents.DASHBAORD_APPLICATIONS_FILTER_CHANGE,
      screenNames.APPLICATIONS,
    );
  };

  const isValid = (data) => {
    if (typeof data === 'string' && data !== '') return true;
    if (typeof data === 'object' && Array.isArray(data) && data.length) return true;
    if (typeof data === 'object' && !Array.isArray(data) && Object.keys(data).length) return true;
    return false;
  };

  const initializeFilters = async () => {
    const filterConfig = await getFilterConfig();
    const { filterList, filterListLength } = await fetchFilterList(filterConfig);
    setAvailableFilterList(filterList);
    setAvailableFilterListLength(filterListLength);
    const initSelectedFilterList = [];
    const initSelectedFilterDetailedList = [];
    const initData = {
      createdAt: { ...application }, ...transactionMetadataFilters, ...metaData, status,
    };
    const initStatus = queryParams.get('status') ? queryParams.get('status').split(',') : [];
    const initWorkflowID = queryParams.get('workflowId');
    const initStartTime = queryParams.get('startDate');
    const initEndTime = queryParams.get('endDate');
    const filterFromQueryParamsToUpdateReduxState = [];

    if (initStatus && initStatus.length) {
      initData.status = {
        ...initData.status,
        value: { values: initStatus, contains: true },
        filterData: {
          contains: true,
          selectedOptions: initStatus.map((initStatusElement) => ({
            value: initStatusElement,
            label: applicationStatusLabelMap[initStatusElement],
          })),
        },
      };
      filterFromQueryParamsToUpdateReduxState.push('status');
    }

    if (initWorkflowID) {
      const workflows = initWorkflowID.split(',');
      const selectedOptions = [];
      const values = [];
      workflows.forEach((workflow) => {
        const ele = workflowList.find((w) => w.id === workflow);
        if (ele) {
          selectedOptions.push({ value: ele.id, label: ele.name || ele.id });
          values.push(ele.id);
        }
      });
      if (selectedOptions.length) {
        initData.workflowId = {
          ...initData.workflowId,
          value: { values, contains: true },
          filterData: { contains: true, selectedOptions },
        };
        filterFromQueryParamsToUpdateReduxState.push('workflowId');
      }
    }

    if (initStartTime && initEndTime) {
      const filterTimeRange = {
        endTime: new Date(+initEndTime).toISOString(),
        startTime: new Date(+initStartTime).toISOString(),
      };
      initData.createdAt = {
        filterData: { selectedDateRange: filterTimeRange },
        value: filterTimeRange,
      };
      filterFromQueryParamsToUpdateReduxState.push('createdAt');
    }

    Object.entries(initData).forEach(([key, { filterData, value }]) => {
      if (isValid(value) && filterList) {
        filterList.some((group) => {
          const filter = group.filters.find((filterItem) => filterItem.key === key);
          if (filter && filterData && isValid(filterData)) {
            const details = { ...filter, ...filterData, value };
            const { filterLabel, value: filterValue } = details.genLabelFunc(details);
            if (filterFromQueryParamsToUpdateReduxState.includes(details.key)) {
              dispatch(details.updateFuntion({
                data: { key: details.key, value: filterValue },
                filterData: details,
              }));
            }
            initSelectedFilterList.push(key);
            initSelectedFilterDetailedList.push({
              details,
              filterLabel,
            });
            return true;
          }
          return false;
        });
      }
    });
    setSelectedFilterList(initSelectedFilterList);
    setSelectedFilterDetailedList(initSelectedFilterDetailedList);
  };

  const initializeTransactionMetadataColumnsConfigs = async () => {
    await getTransactionMetadataColumnsConfig();
  };

  const handleTransactionSearch = (value) => {
    setSearchKey(value);
    formatAnalyticsData(
      email,
      clientId,
      rudderstackEvents.DASHBOARD_APPLICATIONS_SEARCH,
      screenNames.APPLICATIONS,
    );
  };

  useEffect(() => {
    initializeFilters();
    initializeTransactionMetadataColumnsConfigs();
  }, []);

  useEffect(() => {
    if (selectedFilterList.length > 1) setIsSearchOpen(false);
    else setIsSearchOpen(true);
  }, [selectedFilterList]);

  return (
    <>
      <div id="audit__data_container">
        <div id="audit__header_container">
          <div id="audit__filter_container">
            <CommonSearchBar
              placeholder="Search For Name or Transaction ID"
              handleSearch={handleTransactionSearch}
              isInputVisible={isSearchOpen}
              initValue={searchInit}
              isDebounce
            />
            {selectedFilterDetailedList.map(({ details, filterLabel }, idx) => (
              <EditFilter
                key={details.key}
                filterLabel={filterLabel}
                details={details}
                onFilterEdit={(editedDetails) => onFilterEdit(editedDetails, idx)}
                onClose={() => removeFilter(details, idx)}
              />
            ))}
            {availableFilterListlength
            && availableFilterListlength !== selectedFilterList.length ? (
              <AddFilter
                onFilterAdd={onFilterAdd}
                availableFilterList={availableFilterList}
                selectedFilterList={selectedFilterList}
              />
              ) : null}
            <div id="audit__actions_container">
              <p id="audit__element_count">
                {totalElements}
                {' '}
                applications
              </p>
              <button
                type="button"
                id="audit__filter_count_refresh"
                onClick={() => handleDataChange()}
              >
                <img src={RefreshIcon} alt="" aria-hidden />
              </button>

              <CustomPopupContainer
                popupPosition="bottom-left"
                popupTrigger={<TableActionsButton />}
                popupComponent={<TableActions searchValue={searchKey} />}
              />
            </div>
          </div>
        </div>
        <Grid item xs={12}>
          <DataTable
            handlePageChange={handlePageChange}
            handleRowClick={handleRowClick}
            tableColumns={selectedTableColumns}
            isLoading={loading}
            data={auditData}
            page={page}
            totalElements={totalElements}
          />
        </Grid>
      </div>
    </>
  );
}

export default PermissionWrapper(Audit, useGetUserPermissions, getPermission('auditPage'));
