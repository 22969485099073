import axios from 'axios';
import * as Sentry from '@sentry/react';

import { get } from 'lodash';
import googleIcon from '../../assests/images/google.svg';
import productRedirectMapping from '../../utils/productRedirectMapping';

import {
  updateCredState,
  updateEmail, updateIsAuthenticated,
} from '../../reducers/user';
import AlertCode from '../../constants/alertCodes';
import { startEmailOtpAPI } from '../../api/mfa';
import { getRedirectURLFromQueryParams } from '../../utils/helpers';

export const sendOtp = async (setError, setErrorMessage) => {
  try {
    await startEmailOtpAPI();
  } catch (err) {
    setError(true);
    const code = get(err, 'response.data.code');
    if (code === 'mfa/otp-rate-limit') {
      setErrorMessage('Try again after sometime');
      return;
    }
    if (code === 'mfa/invalid-token' || code === 'mfa/expired-token') {
      setErrorMessage('MFA Session expired');
      return;
    }
    setErrorMessage('Something went wrong');
    Sentry.captureException(`${code} - ${err}`, {
      extra: {
        errorMessage: AlertCode.ERROR_SENDING_OTP,
      },
    });
  }
};

const authenticateUser = async (
  dispatch, navigate, setErrorMessage, setOpenInputAlert, email, password, queryParams,
) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/login`,
      data: {
        email,
        password,
      },
    });

    const { isPortalUser, authorizedProducts = [] } = response.data.result;
    dispatch(updateIsAuthenticated(isPortalUser));

    if (!isPortalUser) {
      dispatch(updateEmail(''));
      const productToRedirectTo = authorizedProducts.find(
        (authorizedProduct) => authorizedProduct in productRedirectMapping,
      );
      if (productToRedirectTo) {
        window.location = productRedirectMapping[productToRedirectTo];
      } else if (!queryParams.has('redirect')) {
        setOpenInputAlert(true);
        setErrorMessage(AlertCode.auth.unauthorizedProduct);
      }
    }
    return isPortalUser;
  } catch (error) {
    const errorCode = get(error, 'response.data.errorCode');
    const statusCode = get(error, 'response.status');
    if (errorCode === 'mfa/not-verified' || errorCode === 'mfa/no-factors-registered') {
      window.sessionStorage.setItem('email', email);
      window.sessionStorage.setItem('password', password);
      await sendOtp(setOpenInputAlert, setErrorMessage);
      navigate('/mfa/email/otp/verify');
    } else if (statusCode === 403) {
      setOpenInputAlert(true);
      if (errorCode === 'auth/user-disabled') {
        setErrorMessage(AlertCode.auth.userDisabled);
      } else if (errorCode === 'auth/company-inactive') {
        setErrorMessage(AlertCode.auth.companyInactive);
      }
    } else if (statusCode === 400 || statusCode === 401) {
      setOpenInputAlert(true);
      setErrorMessage('Invalid Email / Password');
    } else {
      setOpenInputAlert(true);
      Sentry.captureException(`${errorCode} - ${error}`, {
        extra: {
          errorMessage: AlertCode.ERROR_AUTHENTICATE_USER,
        },
      });
      setErrorMessage(AlertCode.SOMETHING_WENT_WRONG);
    }
    dispatch(updateEmail(''));
    dispatch(updateIsAuthenticated(false));
    return false;
  }
};

const redirectFromQueryParams = (navigate, queryParams) => {
  const redirectUrl = getRedirectURLFromQueryParams(queryParams);
  if (redirectUrl) {
    navigate(`redirect?url=${encodeURIComponent(redirectUrl)}`);
  }
};

const authenticateUserAndHandleRedirect = async (
  dispatch, navigate, setErrorMessage, setOpenInputAlert, email, password, queryParams,
) => {
  const isValidUser = await authenticateUser(
    dispatch, navigate, setErrorMessage, setOpenInputAlert, email, password, queryParams,
  );
  if (isValidUser) redirectFromQueryParams(navigate, queryParams);
};

export const redirectFunction = (dispatch, navigate, auth, queryParams) => {
  if (auth && !queryParams.has('redirect') && !queryParams.has('recordId')) {
    navigate('/home');
  } else if (auth && queryParams.has('recordId') && queryParams.has('recordIdType') && queryParams.has('recordPage')) {
    dispatch(updateCredState({ appId: queryParams.get('appid') }));
    navigate(`/${queryParams.get('recordPage')}?${queryParams.get('recordIdType')}=${queryParams.get('recordId')}`, {
      state: {
        searchid: queryParams.get('record'),
      },
    });
  }
};

export const startGoogleLogin = async (
  navigate, dispatch, setErrorMessage, setOpenInputAlert, setIsLoading, queryParams,
) => {
  try {
    let successRedirectUrl = `${window.location.origin}/home`;
    if (queryParams.has('recordId') && queryParams.has('recordIdType') && queryParams.has('recordPage')) {
      dispatch(updateCredState({ appId: queryParams.get('appid') }));
      successRedirectUrl = `${window.location.origin}/${queryParams.get('recordPage')}?${queryParams.get('recordIdType')}=${queryParams.get('recordId')}`;
    } else if (queryParams.get('redirect')) {
      successRedirectUrl = `${window.location.origin}/redirect?url=${queryParams.get('redirect')}`;
    }
    const errorRedirectUrl = `${window.location.origin}${window.location.pathname}`;
    const googleAuthRes = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SELF_STAGING_URL}/api/sso/google/start?successRedirectUrl=${successRedirectUrl}&errorRedirectUrl=${errorRedirectUrl}`,
    });
    const redirectUrl = get(googleAuthRes, 'data.redirectUrl');
    if (!redirectUrl) {
      navigate('/');
      setOpenInputAlert(false);
      setErrorMessage('Something went wrong');
    }
    dispatch(updateIsAuthenticated(true));
    window.location.replace(redirectUrl);
  } catch (error) {
    setOpenInputAlert(false);
    setIsLoading(false);
    const errorCode = get(error, 'response.data.code') || get(error, 'response.data.errorCode');
    const statusCode = get(error, 'response.status');
    let errMsg = 'Error authenticating user';
    if (statusCode === 403 && errorCode === 'user/not-found') {
      errMsg = 'Account does not exist. Choose another account or contact admin';
      setErrorMessage(errMsg);
    }
    Sentry.captureException(`${errorCode} - ${error}`, {
      extra: {
        errorMessage: errMsg,
      },
    });
    setErrorMessage('Something went wrong');
  }
};

export function IconLeft({ ...props }) {
  // eslint-disable-next-line react/react-in-jsx-scope, react/jsx-props-no-spreading
  return <img width="20px" height="20px" src={googleIcon} alt="G" {...props} />;
}

export default authenticateUserAndHandleRedirect;
